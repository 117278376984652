@font-face {
    font-family: 'Basier Circle';
    font-style: normal;
    font-weight: 400;
    src: local('Basier Circle'), url('https://fonts.cdnfonts.com/s/20637/BasierCircle-Regular.woff') format('woff');
}
@font-face {
    font-family: 'Basier Square';
    font-style: normal;
    font-weight: 400;
    src: local('Basier Square'), url('https://fonts.cdnfonts.com/s/20637/BasierSquare-Regular.woff') format('woff');
}


